import chair from './icons/chair.svg'
import kitchenHat from './icons/kitchen-hat.svg'
import one from './icons/one.svg'
import screen from './icons/screen.svg'
import shower from './icons/shower.svg'
import sparkles from './icons/sparkles.svg'
import temperature from './icons/temperature.svg'
import two from './icons/two.svg'
import wifi from './icons/wifi.svg'

export const spaces = [
    {
      id: 'salles-de-formation',
      title: 'Salles de formation / réception',
      description: "2 salles de formation majestueuses, idéales pour dispenser vos formations, organiser vos réunions d'équipe ou inviter vos clients.",
      picture: 'salle-formation.jpg',
      price: {
        value: 320,
        per: "/ mois"
      },
      features: [
        {
          icon: one,
          text: "Une salle de 60+ m2"
        },
        {
          icon: two,
          text: "Une salle de 40+ m2"
        },
        {
          icon: chair,
          text: "Hall d’accueil majestueux"
        },
        {
          icon: kitchenHat,
          text: "Cuisine équipée"
        },
        {
          icon: wifi,
          text: "Réseau fibre 1 Gb"
        },
        {
          icon: screen,
          text: "Grand écran"
        }
      ]
    },
    {
      id: 'open-spaces',
      title: 'Open-spaces',
      description: "2 grands open-spaces au premier étage, entièrement équipés.",
      picture: 'open-space.jpg',
      price: {
        value: 7,
        per: "/ m2 / mois"
      },
      features: [
        {
          icon: one,
          text: "Un open-space de 35 m2"
        },
        {
          icon: two,
          text: "Un open-space de 50 m2"
        },
        {
          icon: shower,
          text: "Toilettes, douches"
        },
        {
          icon: kitchenHat,
          text: "Cuisine équipée"
        },
        {
          icon: wifi,
          text: "Réseau fibre 1 Gb"
        },
        {
          icon: temperature,
          text: "Plancher chauffant / rafraîchissant"
        }
      ]
    },
    {
      id: 'bureaux',
      title: 'Bureaux privés',
      description: "Plusieurs bureaux privés au 1er et au 2ème étage, idéals pour rester concentré sans être dérangé.",
      picture: 'bureau-prive.jpg',
      price: {
        value: 45,
        per: "/ m2 / mois"
      },
      features: [
        {
          icon: chair,
          text: "Bureaux de 9 à 12 m2"
        },
        {
          icon: sparkles,
          text: "Hall d’accueil majestueux"
        },
        {
          icon: shower,
          text: "Toilettes, douches"
        },
        {
          icon: kitchenHat,
          text: "Cuisine équipée"
        },
        {
          icon: wifi,
          text: "Réseau fibre 1 Gb"
        },
        {
          icon: temperature,
          text: "Plancher chauffant / rafraîchissant"
        }
      ]
    },
  ]