import './Features.css';
import car from './icons/car.svg'
import drop from './icons/drop.svg'
import key from './icons/key.svg'
import sofa from './icons/sofa.svg'

const FeatureCard = ({title, icon, children}) => (
  <li className="card">
    <div className="img-wrapper">
      <img src={icon} alt={title} />
    </div>
    <div className="description">
      <h4 dangerouslySetInnerHTML={{__html: title}}></h4>
      <p>
        {children}
      </p>
    </div>
  </li>
)

const Features = () => {
  return (
    <section id="features">
      <div className="section-description">
        <h2>
          Également inclus, en libre service...
        </h2>
      </div>
      <ul>
        <FeatureCard title="Espaces repos" icon={sofa}>
          Des espaces repos en libre-service pour pouvoir être plus productif.
        </FeatureCard>
        <FeatureCard title="Sanitaires avec douches" icon={drop}>
          Salles de bains entièrement rénovées, avec accès PMR.
        </FeatureCard>
        <FeatureCard title="Places de parking privées" icon={car}>
          De nombreuses places de places de parking, avec 2 bornes électriques.
        </FeatureCard>
        <FeatureCard title="Accès sécurisé 24/7" icon={key}>
          Système d'alarme à la pointe et clefs attitrées pour les espaces privés.
        </FeatureCard>
      </ul>
    </section>
  );
}

export default Features;