import Button from '../../common/Button/Button';
import './Map.css';
// import map from './map-compressed.svg'
import castle from './castle.svg'

const Map = () => {
  return (
    <section id="map">
      <div className="section-description">
        <strong>Emplacement</strong>
        <h2>
          Tous les commerces,<br/>
          accessibles à pied.
        </h2>
        <p>
          Située en plein centre-ville, la Guéraude est idéalement située 
          à proximité des principaux commerces et points de vie de Salon-de-Provence.
        </p>
      </div>
      <div className="map-wrapper">
        <div className="section-description floating">
          <strong>Emplacement</strong>
          <h2>
            Tous les commerces,<br/>
            accessibles à pied.
          </h2>
          <p>
            Située en plein centre-ville, la Guéraude est idéalement située 
            à proximité des principaux commerces et points de vie de Salon-de-Provence.
          </p>
          <div className="buttons">
            <Button href={process.env.REACT_APP_CONTACT_URL}>
              Visiter les lieux
            </Button>
            <Button variant="secondary" href="https://maps.app.goo.gl/r3erHTX63PTsmwqFA" target="_blank">
              Voir sur la carte
            </Button>
          </div>
        </div>
        <img
          src="map-compressed.svg"
          alt="carte de Salon de Provence"
          loading="lazy"
          className="map-large" />
        <img
          src="map-centered-compressed.svg"
          alt="carte de Salon de Provence"
          loading="lazy"
          className="map-centered" />
        <div className="indicator">
          <strong>La Guéraude</strong>
          <span className="circle">
            <img src={castle} alt="Château" />
          </span>
        </div>
      </div>
      <div className="buttons">
        <Button href={process.env.REACT_APP_CONTACT_URL}>
          Visiter les lieux
        </Button>
        <Button variant="secondary" href="https://maps.app.goo.gl/r3erHTX63PTsmwqFA" target="_blank">
          Voir sur la carte
        </Button>
      </div>
    </section>
  );
}

export default Map;