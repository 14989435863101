import { useState } from 'react';
import Navbar from './common/Navbar/Navbar';
import Features from './sections/Features/Features';
import Footer from './sections/Footer/Footer';
import Hero from './sections/Hero/Hero';
import HeroYT from './sections/Hero/HeroYT';
import Map from './sections/Map/Map';
import Presentation from './sections/Presentation/Presentation';
import Services from './sections/Services/Services';
import Testimonials from './sections/Testimonials/Testimonials';
import NavMenu, { navMenuContext } from './common/NavMenu/NavMenu';
import Loader from './sections/Loader/Loader';

function App() {
  const [isMenuShown, setIsMenuShown] = useState(false);
  const toggleMenu = (toggle) => {
    setIsMenuShown(toggle)
  }

  return (
    <navMenuContext.Provider value={{isMenuShown, toggleMenu}}>
      <Loader />
      <Navbar theme="dark" />
      <Hero />
      <Presentation />
      <Services />
      <Features />
      <Map />
      <Testimonials />
      <Footer />
      <NavMenu />
    </navMenuContext.Provider>
  );
}

export default App;
