import { useEffect, useState } from 'react';
import './Loader.css';

const Loader = () => {
  const [isLoaded, setLoaded] = useState(document.readyState === 'complete')
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    // Here you can access your API to get the Network state instead on document ready
    const destroy = () => {
      document.removeEventListener("readystatechange", readyListener)
    }
    const readyListener = () => {
      // console.log(document.readyState)
      if (document.readyState === 'complete') {
        setLoaded(true)
        clearInterval(timer)
      }
    }

    const timer = setInterval(() => {
      if (isLoaded || counter < 75) {
        let randomIncrement = Math.floor(Math.random() * 10) + 1
        if (isLoaded) {
          randomIncrement += 25
        }
        const newCounter = Math.min(100, counter + randomIncrement)
        setCounter(newCounter)
        // console.log("ongoing")
        clearInterval(timer)
      }
      // else {
      //   console.log("stuck")
      // }

      // Select the video element
      // const video = document.querySelector("video");
      // Tell the browser to load the video
      // video.load();

      return () => destroy()
    }, 300)

    document.addEventListener("readystatechange", readyListener);
    return () => destroy()
  }, [counter, isLoaded])

  return (
    <div id="loader" className={counter === 100 ? 'loaded' : null}>
      <div className="center">
        <div className="logo-mask">
          <div className="fill" style={{width: `${counter}%` }}>
            <div className="moving-gradient"></div>
          </div>
        </div>
        <span className="counter">{ counter } %</span>
      </div>
    </div>
  );
}

export default Loader;