import './Testimonials.css';
import quotes from './quotes-double.svg'

const CommentCard = ({author, date, color, className, children}) => (
  <article className={`comment-card ${className}`} style={{backgroundColor: color}}>
    <img src={quotes} alt="quote"/>
    <p>
      {children}
    </p>
    <div className="meta">
      <span className="author">{author}</span>
      <span>{date}</span>
    </div>
  </article>
)

const Testimonials = () => (
  <section id="testimonials">
    <div className="section-description">
      <strong>Témoignages</strong>
      <h2>
        Ils y ont travaillé,<br/>
        ils témoignent
      </h2>
    </div>
    <CommentCard className="card-1" author="Nadia" date="Janv. 2024" color="#DCD0A8">
      Une magnifique demeure où il fait bon travailler, 
      de grands espaces et des prestations haut de gamme.
    </CommentCard>
    <CommentCard className="card-3" author="Cassandre" date="Sept. 2023" color="#C9B49B">
      Cette maison de savonnier est très bien restaurée,
      tout en gardant un caractère authentique.
      C’est très agréable d’y travailler.
    </CommentCard>
    <CommentCard className="card-2" author="Julien" date="Août. 2023" color="#B5E29A">
      Les salles de formation sont grandes et belles,
      avec une connectique au top,
      parfait pour de grandes réunions.
    </CommentCard>
  </section>
)

export default Testimonials;