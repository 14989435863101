/* Scrolle jusqu'à une destination cible */
export const scrollTo = (e) => {
    e.preventDefault();
    const element = e.target.closest("a")
    let target = element.getAttribute("href")
    if (!target) {
      console.warn("no target found")
      return
    }
    if (target === '#') {
      target = '#home'
    }
    const targetElement = document.querySelector(target)
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth'
      })
    }
}