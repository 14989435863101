import Button from '../../common/Button/Button';
import './Footer.css';
// import jardin from './jardin.jpg'
// import facebook from './facebook-f.svg'
// import instagram from './instagram.svg'
// import x from './twitter-x.svg'
// import { ReactComponent as FacebookIcon } from './facebook-f.svg'
// import { ReactComponent as InstagramIcon } from './instagram.svg'
// import { ReactComponent as TwitterXIcon } from './twitter-x.svg'
import { scrollTo } from '../../common/Utils';

const Footer = () => (
  <section id="footer">
    <img src="/jardin-lowq.jpg" alt="jardin château" className="jardin" loading="lazy" />
    <footer>
      <div className="cta">
        <h2>
          Optez à présent<br/>
          pour un cadre<br/>
          de travail<br/>
          unique.
        </h2>
        <Button href={process.env.REACT_APP_CONTACT_URL}>
          Programmer une visite
        </Button>
      </div>
      <div className="links">
        <div className="top">
          <nav>
            <h4>
              Contact
            </h4>
            <ul>
              <li>
                <small>E-mail</small>
                <a href={process.env.REACT_APP_CONTACT_URL}>
                  contact@gestonline.com
                </a>
              </li>
              <li>
                <small>Téléphone</small>
                <a href="tel:+33490564233">
                  04 90 56 42 33
                </a>
              </li>
            </ul>
          </nav>
          <nav className="sections">
            <h4>
              La Guéraude
            </h4>
            <ul>
              <li>
                <a onClick={scrollTo} href="#presentation">
                  Présentation
                </a>
              </li>
              <li>
                <a onClick={scrollTo} href="#services">
                  Les espaces
                </a>
              </li>
              <li>
                <a onClick={scrollTo} href="#map">
                  Emplacement
                </a>
              </li>
              <li>
                <a onClick={scrollTo} href="#testimonials">
                  Témoignages
                </a>
              </li>
            </ul>
          </nav>
          <nav className="services">
            <h4>
              Les espaces
            </h4>
            <ul>
              <li>
                <a onClick={scrollTo} href="#salles-de-formation">
                  Salles de formation
                </a>
              </li>
              <li>
                <a onClick={scrollTo} href="#salles-de-formation">
                  Salles de réception
                </a>
              </li>
              <li>
                <a onClick={scrollTo} href="#bureaux">
                  Bureaux privés
                </a>
              </li>
              <li>
                <a onClick={scrollTo} href="#open-spaces">
                  Open spaces
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          {/* <nav>
            <h4>Suivez-nous sur les réseaux</h4>
            <ul className="social-links">
              <li>
                <a href={process.env.REACT_APP_SOCIAL_LINK_FACEBOOK}>
                  <FacebookIcon/>
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_SOCIAL_LINK_X}>
                  <TwitterXIcon/>
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_SOCIAL_LINK_INSTAGRAM}>
                  <InstagramIcon/>
                </a>
              </li>
            </ul>
          </nav> */}
          <strong className="copyright">
            <span>La Guéraude Co-working</span>
            2023 @ Salon de Provence
          </strong>
        </div>
      </div>
    </footer>
  </section>
)

export default Footer;