import Button from '../../common/Button/Button';
import Navbar from '../../common/Navbar/Navbar';
import './Hero.css';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { scrollTo } from '../../common/Utils';

const Hero = () => {
  return (
    <section id="hero" className="full-screen">
      <section className="mask">
        <Navbar theme="light"/>
        <video className="background" poster="chateau-face.jpg" autoPlay muted controls={false} loop>
          <source src="plan-drone-1080-29fps-crf50.webm" type="video/webm" />
          {/* <source src="plan-drone.mp4" type="video/mp4" /> */}
          Your browser does not support the video tag.
        </video>
        <div className="center">
          <h1>
              Votre espace <strong>co‑working</strong><br/>
              au coeur de la Provence
          </h1>
          <p>
              Installez-vous dans une ancienne maison de Savonnier,&nbsp;
               <span>en plein coeur de Salon de Provence.</span>
          </p>
          <Button variant="primary-light" href={process.env.REACT_APP_CONTACT_URL}>
            Visiter la Guéraude
          </Button>
        </div>
        <div className="scroll-appeal">
          <span className="label">Découvrir</span>
          <Button variant="secondary-light" className="circle" href="#presentation" onClick={scrollTo}>
            <ArrowDown/>
          </Button>
        </div>
      </section>
    </section>
  );
}

export default Hero;