import { useContext } from 'react';
import Button from '../Button/Button';
import { scrollTo } from '../Utils';
import './Navbar.css';
import { navMenuContext } from '../NavMenu/NavMenu';
import { ReactComponent as MenuBars } from './menu-bars.svg';

const Navbar = ({theme}) => {
    const { toggleMenu } = useContext(navMenuContext)
    return (
        <header className={`navbar theme--${theme}`}>
            <a className="app-name" href="#hero" onClick={scrollTo}>
                La Guéraude
            </a>
            <nav>
                <ul>
                    <li>
                        <a href="#presentation" onClick={scrollTo}>
                            Présentation
                        </a>
                    </li>
                    <li>
                        <a href="#services" onClick={scrollTo}>
                            Espaces
                        </a>
                    </li>
                    <li>
                        <a href="#map" onClick={scrollTo}>
                            Localisation
                        </a>
                    </li>
                    <li>
                        <a href="#footer" onClick={scrollTo}>
                            Contact
                        </a>
                    </li>
                </ul>
            </nav>
            <div className="buttons">
                <Button
                    className="cta"
                    variant={`secondary${theme === 'light' ? '-light' : ''}`}
                    href={process.env.REACT_APP_CONTACT_URL}>
                    Visiter les lieux
                </Button>
                <Button
                    className="open-navbar"
                    variant={`secondary${theme === 'light' ? '-light' : ''}`}
                    onClick={() => {toggleMenu(true)}}
                    circle>
                    <MenuBars/>
                </Button>
            </div>
        </header>
    );
}

export default Navbar;