import { createContext, useContext } from 'react';
import './NavMenu.css';
import Button from '../Button/Button';
import { scrollTo } from '../Utils';
import { ReactComponent as Xmark } from './xmark.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';

export const navMenuContext = createContext();

const NavMenu = () => {
    const { isMenuShown, toggleMenu } = useContext(navMenuContext)
    const closeAndScrollTo = (e) => {
        toggleMenu()
        scrollTo(e)
    }
    return (
        <>
            <div
                className={`overlay ${isMenuShown ? 'shown' : ''}`}
                onClick={() => {toggleMenu(false)}}></div>
            <nav id="nav-menu" className={`${isMenuShown ? 'shown' : ''}`}>
                <div className="top">
                    <a className="app-name" href="#hero" onClick={scrollTo}>
                        La Guéraude
                    </a>
                    <Button
                        variant="secondary-light"
                        circle
                        onClick={() => {toggleMenu(false)}}>
                        <Xmark/>
                    </Button>
                </div>
                <ul>
                    <li>
                        <Button href="#presentation" onClick={closeAndScrollTo} variant="secondary-light">
                            Présentation
                            <ArrowRight/>
                        </Button>
                    </li>
                    <li>
                        <Button href="#services" onClick={closeAndScrollTo} variant="secondary-light">
                            Services
                            <ArrowRight/>
                        </Button>
                    </li>
                    <li>
                        <Button href="#map" onClick={closeAndScrollTo} variant="secondary-light">
                            Emplacement
                            <ArrowRight/>
                        </Button>
                    </li>
                    <li>
                        <Button href="#testimonials" onClick={closeAndScrollTo} variant="secondary-light">
                            Témoignages
                            <ArrowRight/>
                        </Button>
                    </li>
                    <li>
                        <Button href={process.env.REACT_APP_CONTACT_URL} variant="primary-light">
                            Programmer une visite
                            <ArrowRight/>
                        </Button>
                    </li>
                </ul>
                <div className="contact">
                    <h3>
                        Contact
                    </h3>
                    <ul>
                    <li>
                        <small>E-mail</small>
                        <a href="mailto:contact@gestonline.com">
                        contact@gestonline.com
                        </a>
                    </li>
                    <li>
                        <small>Téléphone</small>
                        <a href="tel:+33490564233">
                        04 90 56 42 33
                        </a>
                    </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default NavMenu;