import Button from '../../common/Button/Button';
import './Presentation.css';
import porte from './porte-principale.jpg';

const Presentation = () => {
  return (
    <section id="presentation">
      <div className="section-description">
        <strong>Présentation</strong>
        <h2>
          Bienvenue à la Guéraude
        </h2>
      </div>
      <div className="wrapper">
        <div className="subsection left">
          <h3>
            Vivez le charme de l’ancien...
          </h3>
          <p>
            Dans une authentique maison de Savonnier au coeur de Salon de Provence, 
            la Guéraude est le lieu idéal pour trouver l’inspiration.
          </p>
        </div>
        <div className="img-wrapper">
          <img src={porte} alt="Hall d'entrée" />
        </div>
        <div className="subsection right">
          <h3>
            Couplé au confort du moderne.
          </h3>
          <p>
            Entièrement équipée à la pointe de la technologie,
            elle est prête à accueillir vos équipes et vos clients.
          </p>
        </div>
      </div>
      <Button href="#services">
        Voir les espaces proposés
      </Button>
    </section>
  );
}

export default Presentation;