import { scrollTo } from '../Utils';
import './Button.css';

const Button = ({children, variant="primary", href=null, circle=false, className="", ...rest}) => {
  const isAnchor = href?.startsWith('#')
  return (
    <a  className={`${className} button ${variant} ${circle ? 'circle' : ''}`}
        href={href}
        onClick={isAnchor ? scrollTo : undefined}
        {...rest}>
      {children}
      <span className="circle">
        {children}
      </span>
    </a>
  );
}

export default Button;