import Button from '../../common/Button/Button';
import './Services.css';
import { spaces } from './Spaces'

const SpaceSubSection = ({position='left', title, description, picture, features, price, ...rest}) => (
  <article className={`position--${position}`} {...rest}>
    <div className="details">
      <div className="description">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <ul className="feature">
      {
        features?.map((feature, key) => (
          <li key={key}>
            <span className="icon-wrapper">
              <img src={feature.icon} alt={feature.text} />
            </span>
            <p>
              {feature.text}
            </p>
          </li>
        ))
      }
      </ul>
      <div className="bottom">
        <Button href={process.env.REACT_APP_CONTACT_URL}>
          Visiter cet espace
        </Button>
        <div>
          <small>À partir de</small>
          <div className="price">
            <span className="value">
              {price.value}
              <span className="unit">
                €
              </span>
            </span>
            <span className="per">
              {price.per}
            </span>
          </div>
        </div>
      </div>
    </div>
    <img src={picture} alt={title} loading="lazy" />
  </article>
)

const Services = () => {
  return (
    <section id="services">
      <div className="section-description">
        <strong>Espaces</strong>
        <h2>
          Des espaces confortables<br/>
          pour vos équipes et vos clients
        </h2>
        <p>
          Nous mettons à votre disposition plusieurs pièces, <br/>
          pour la durée souhaitée (1 jour, 1 semaine, 1 mois...)
        </p>
      </div>
      {
        spaces.map((space, i) => (
          <SpaceSubSection key={i} position={i % 2 === 0 ? 'left' : 'right'} {...space} />
        ))
      }
    </section>
  );
}

export default Services;